import React, { useState } from 'react'
import { BsFilterLeft } from "react-icons/bs"
import { useStaticQuery, graphql } from 'gatsby'
import Image from "gatsby-image"
import { useIntl } from 'gatsby-plugin-intl'

const getLogoImage = graphql`
  {
    idm: file(relativePath: { eq: "idmlogo.webp" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`

export const Filter = ({ handleInputChange, filters, currentFilter }) => {

  const { idm: { childImageSharp: { fixed } } } = useStaticQuery(getLogoImage)
  const [isFilter, setIsFilter] = useState(false)
  const handleFilter = () => {
    setIsFilter(!isFilter)
  }
  const intl = useIntl()

  return (
    <div className="echipamente_filter">
      <div className="echipamente_filter_content">
        <Image fixed={fixed} alt="idm-logo" />
        <h1 onClick={handleFilter}>{intl.formatMessage({ id: "equipmentTips" })} <BsFilterLeft /></h1>
        <div className={isFilter ? "echipamente_filter_content_inputs show-filters" : "echipamente_filter_content_inputs"}>
          {
            [...filters].map((filter, i) => {
              return <div key={i} itemProp="category" className={currentFilter === filter ? "echipamente_list-item_details filter filter-active" : "echipamente_list-item_details filter"} onClick={(e, currentFilter) => handleInputChange(e, filter)}>
                {filter}
              </div>
            })
          }
        </div>
      </div>
    </div>
  )
}
