import React, { useRef } from "react"
import Modal from "./Modal"
import Image from "gatsby-image"
import { StyledButton } from "./StyledButton"
import { useIntl } from "gatsby-plugin-intl"
import useOutsideClick from "@rooks/use-outside-click"

export const Details = ({ product, handlePortal, setIsPortal, isPortal }) => {

  const intl = useIntl()

  const descriptionArray = intl.locale === "en" ? (product.node.description !== '"0"' ? product.node.category.descriptionEn.split("/") : "") :
    (product.node.description !== '"0"' ? product.node.category.descriptionRo.split("/") : "")
  const splitName = product.node.name.toLowerCase().split(" ");

  const modalRef = useRef();

  function outsideModalClick() {
    setIsPortal(!isPortal)
  }

  useOutsideClick(modalRef, outsideModalClick);

  return (
    <Modal>
      <div className="modal">
        <div ref={modalRef} className="echipamente_details echipamente_details-zoom1">
          <div className="div_container">
            {
              product.pdf ?
                <a itemProp="url" href={product.pdf} target="_blank" rel="noreferrer">
                  <Image itemProp="image" fluid={product.node.image.fluid} alt={(splitName[0] + "_" + splitName.slice(1)).replace(/,/g, "")} className="echipamente_gatsby_img" />
                </a>
                :
                <Image itemProp="image" fluid={product.node.image.fluid} alt={(splitName[0] + "_" + splitName.slice(1)).replace(/,/g, "")} className="echipamente_gatsby_img" />
            }
          </div>
          <span className="echipamente_cross" onClick={handlePortal}>
            ×
          </span>
          <div className="echipamente_details_content">
            <h1 style={{ marginBottom: "0.5em" }} itemProp="name">{product.node.name}</h1>
            <ul itemProp="description">
              {descriptionArray ? descriptionArray.map((description, i) => {
                return <li style={{ marginBottom: "0" }} key={i}>{description}</li>
              }) : ""}
            </ul>
            <div style={{ marginTop: "0.5em" }}>
              <StyledButton pdf={product.pdf} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
  return <></>
}
