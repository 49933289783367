import React, { useState } from "react"
import Image from "gatsby-image"
import { Details } from "./Details"
import "./details.styles.scss"
import { useIntl } from "gatsby-plugin-intl"

const Product = ({ product }) => {
  const { name, image, category } = product.node
  const [isPortal, setIsPortal] = useState(false)

  const handlePortal = () => {
    setIsPortal(!isPortal)
  }

  const splitName = name.toLowerCase().split(" ")
  const intl = useIntl()

  return (
    <div itemProp="itemListElement" itemType="https://schema.org/Product" className="echipamente_list-item shadow-effect">
      <div className="echipamente_list_container" onClick={category.descriptionRo && handlePortal}>
        <span style={{ zIndex: "1", display: "none" }} className="echipamente_list_secondary">{category.secondary !== '0' ? category.secondary : ""}</span>
        <div className="image_content">
          <Image itemProp="image" fluid={image.fluid} alt={(splitName[0] + "_" + splitName.slice(1)).replace(/,/g, "")} />
          <div className="echipamente_list_content">
            <hr />
            <h2 itemProp="name">{name}</h2>
          </div>
          <div style={{ visibility: "hidden", height: "0" }} itemProp="brand">IDM</div>
        </div>
        <div className={category.descriptionRo === null ? "echipamente_list-item_details disabled" : "echipamente_list-item_details"} onClick={category.descriptionRo && handlePortal}>
          {intl.formatMessage({ id: "equipmentDetails" })}
        </div>
      </div>
      {isPortal && <Details product={product} handlePortal={handlePortal} setIsPortal={setIsPortal} isPortal={isPortal} />}
    </div>
  )
}

export default Product
