import React from 'react'
import Product from './Product'

const List = ({ products, currentFilter }) => {
    return (
        <div className="echipamente_list" itemScope itemType="https://schema.org/ItemList">
            {
                products.filter(product => currentFilter === "All" || product.node.category.primaryRo === currentFilter || product.node.category.primaryEn === currentFilter).map((product, i) => {
                    return <Product product={product} key={i} />
                })
            }
        </div>
    )
}

export default List