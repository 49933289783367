import React, { useState, useEffect } from "react"
import "./main.styles.scss"
import { useStaticQuery, graphql } from "gatsby"
import List from "./Products/List"
import { Filter } from "./Filter/Filter"
import { useIntl } from "gatsby-plugin-intl"

const getProducts = graphql`
  {
    products: allContentfulProduct {
      edges {
        node {
          name,
          description
          category {
            primaryRo,
            primaryEn,
            secondary,
            descriptionRo,
            descriptionEn
          }
          image {
            fluid(quality:100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    
      pdfs:allFile(filter:{extension:{eq:"pdf"}}) {
       edges {
         node {
           publicURL,
           name
         }
       }
     }
  }
`

const Main = () => {
  const { products, pdfs } = useStaticQuery(getProducts)
  const [currentFilter, setCurrentFilter] = useState("Aer-Apa")
  const intl = useIntl()

  const productsArray = products.edges.map(product => {
    let productWithPdf = { ...product, pdf: 0 }
    pdfs.edges.forEach(pdf => {
      if (pdf.node.name.toLowerCase() === product.node.name.split(" ").join``.toLowerCase())
        productWithPdf = { ...product, pdf: pdf.node.publicURL }
    })
    return productWithPdf
  })

  const getCategoriesRo = () => {
    let categories = productsArray.map(product => product.node.category.primaryRo)
    let primarySet = Array.from(new Set(categories))
    return primarySet
  }

  const getCategoriesEn = () => {
    let categories = productsArray.map(product => product.node.category.primaryEn)
    let primarySet = Array.from(new Set(categories))
    return primarySet
  }

  const [inputs, setInputs] = useState({
    categories: intl.locale === "en" ? getCategoriesEn() : getCategoriesRo(),
    currentCategories: [],
    currentProducts: products.edges,
    numbers: 0,
  })

  const handleInputChange = (e, selectedFilter) => {
    setCurrentFilter((selectedFilter === "Aer-Apa" || selectedFilter === "Air-Water") ? "Aer-Apa" : selectedFilter)
  }

  useEffect(() => {
    setInputs({
      categories: intl.locale === "en" ? getCategoriesEn() : getCategoriesRo(),
      currentCategories: [],
      currentProducts: products.edges,
      numbers: 0,
    })
  }, [intl.locale])

  return (
    <div className="echipamente_products">
      <Filter
        handleInputChange={handleInputChange}
        filters={inputs.categories}
        currentFilter={currentFilter}
      />
      <List products={productsArray} currentFilter={currentFilter} />
    </div>
  )
}

export default Main
