import React from "react"
import Layout from "../components/Layout/Layout"
import { IDM } from "../components/Echipamente/IDM"
import Main from "../components/Echipamente/Main"
import { StyledHeader } from "../components/Header/Header"
import { graphql } from "gatsby"
import SEO from "../components/SEO/SEO"

import TagManager from "../components/TagManager/TagManager"

export default ({ data }) => {
  return (
    <Layout>
      <TagManager />
      <SEO
        title="Pompe de caldura iDM"
        description="Pompele de caldura iDM au o eficienta crescuta, un cost redus si se bucura de o durata de viata ridicata."
        robots="index, follow"
        canonical="https://g4h.ro/echipamente"
        openGraph={{ url: "https://www.g4h.ro/echipamente", type: "website", image: "https://images.ctfassets.net/kpyobmg5j3e2/5RYNaMie2MrZkPk7ybuuJ/d86b4260b10c55de0c955e7a06d6c7c5/6-removebg-preview.png?w=535&h=466&q=100", title: "Pompe de caldura iDM", description: "Pompele de caldura iDM au o eficienta crescuta, un cost redus si se bucura de o durata de viata ridicata." }}
      />
      <StyledHeader
        echipamente
        image={data.contactheader.childImageSharp.fluid}>
        <IDM />
      </StyledHeader>
      <Main />
      <div id="modal-root"></div>
    </Layout>
  )
}

export const query = graphql`
  {
    contactheader: file(relativePath: { eq: "fundal.webp" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
